import * as React from "react";

const JustBLogo = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 106 97"
  >
    <path
      fill="#0062FF"
      fillRule="evenodd"
      d="M41.57 8.252C41.57 3.695 45.285 0 49.87 0s8.301 3.695 8.301 8.252v28.371a32.2 32.2 0 0 1 15.614-4.006c17.792 0 32.215 14.34 32.215 32.027 0 17.689-14.423 32.028-32.215 32.028H30.106c-4.148 0-7.51-3.343-7.51-7.467s3.362-7.466 7.51-7.466h10.87c2.62 0 4.744-2.111 4.744-4.715 0-2.605-2.124-4.717-4.744-4.717H14.295c-4.148 0-7.51-3.342-7.51-7.466s3.362-7.466 7.51-7.466h26.484c2.728 0 4.94-2.2 4.94-4.913s-2.212-4.912-4.94-4.912H30.106c-4.148 0-7.51-3.343-7.51-7.466s3.362-7.467 7.51-7.467h11.463zM58.17 64.645c0 8.572 6.99 15.522 15.614 15.522 8.623 0 15.614-6.95 15.614-15.523 0-8.572-6.99-15.522-15.614-15.522-8.623 0-15.614 6.95-15.614 15.522"
      clipRule="evenodd"
    ></path>
    <path
      fill="#0062FF"
      d="M15.264 39.856a7.632 7.632 0 1 1-15.264 0 7.632 7.632 0 0 1 15.264 0"
    ></path>
  </svg>
);

export default JustBLogo;
